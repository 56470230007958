import React, { FC } from "react";
import { WindowLocation } from "@reach/router";
import Scrollspy from "react-scrollspy";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { Fragment } from "react";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

interface IHeader {
  location: WindowLocation<unknown>;
}

export const Header: FC<IHeader> = ({ location }) => {
  const activeNavLink = `border-transparent text-gray-900 border-blue-900 inline-flex items-center px-1 pt-1 border-b-2 text-xl font-semibold`;
  const inActiveNavLink = `border-transparent text-gray-900 hover:border-blue-900 hover:text-blue-900 inline-flex items-center px-1 pt-1 border-b-2 text-xl font-semibold`;

  const mobileInactiveLink = `border-transparent text-gray-900 block pl-3 pr-4 py-2 border-l-4 text-lg font-medium`;
  const mobileActiveLink = `text-gray-900 border-blue-900 block pl-3 pr-4 py-2 border-l-4 text-lg font-medium`;
  return (
    <Scrollspy
      items={["hero", "education", "research", "faq", "contact"]}
      currentClassName="is-current"
      componentTag="div"
    >
      <Disclosure as="nav" className="bg-gray-50 shadow font-urbanist">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-1 lg:py-7">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="flex-shrink-0 flex items-center">
                    <Link to="/">
                      <div className="block lg:hidden">
                        <StaticImage
                          className="block lg:hidden h-16 w-16"
                          src="../../../images/icon.png"
                          alt="Kidneys"
                          placeholder="tracedSVG"
                        />
                      </div>
                    </Link>
                    <Link to="/">
                      {" "}
                      <div className="hidden lg:block">
                        <StaticImage
                          className="hidden lg:block h-20 w-20"
                          src="../../../images/icon.png"
                          alt="Kidneys"
                          placeholder="tracedSVG"
                        />
                      </div>
                    </Link>
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                    <Link
                      to={
                        location.pathname === "/" ? "#education" : "/#education"
                      }
                      className={inActiveNavLink}
                    >
                      Education
                    </Link>
                    <Link
                      to={
                        location.pathname === "/" ? "#research" : "/#research"
                      }
                      className={inActiveNavLink}
                    >
                      Research
                    </Link>
                    <Link
                      to={location.pathname === "/" ? "#faq" : "/#faq"}
                      className={inActiveNavLink}
                    >
                      FAQ
                    </Link>

                    {/* <Link
                      to={location.pathname === "/" ? "#contact" : "/#contact"}
                      className={inActiveNavLink}
                    >
                      Contact Me
                    </Link> */}
                  </div>
                </div>
                <div className="hidden sm:flex">
                  <Link
                    to="/blog"
                    className={
                      location.pathname.startsWith("/blog")
                        ? activeNavLink + " mr-8"
                        : inActiveNavLink + " mr-8"
                    }
                  >
                    Blog
                  </Link>
                  {/* <Link
                    to="/about"
                    className={
                      location.pathname.startsWith("/about")
                        ? activeNavLink
                        : inActiveNavLink
                    }
                  >
                    About Me
                  </Link> */}
                </div>

                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="pt-2 pb-3 space-y-1">
                {/* Current: "bg-green-50 border-green-500 text-green-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                <Link
                  to={location.pathname === "/" ? "#education" : "/#education"}
                  className="border-transparent text-gray-900 block pl-3 pr-4 py-2 border-l-4 text-lg font-medium"
                >
                  Education
                </Link>
                <Link
                  to={location.pathname === "/" ? "#research" : "/#research"}
                  className="border-transparent text-gray-900 block pl-3 pr-4 py-2 border-l-4 text-lg font-medium"
                >
                  Research
                </Link>
                <Link
                  to={location.pathname === "/" ? "#faq" : "/#faq"}
                  className="border-transparent text-gray-900 block pl-3 pr-4 py-2 border-l-4 text-lg font-medium"
                >
                  FAQ
                </Link>
                {/* <Link
                  to={location.pathname === "/" ? "#awards" : "/#awards"}
                  className="border-transparent text-gray-900 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  Awards
                </Link> */}
                {/* <Link
                  to={location.pathname === "/" ? "#contact" : "/#contact"}
                  className="border-transparent text-gray-900 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  Contact Me
                </Link> */}
                <Link
                  to="/blog"
                  className={
                    location.pathname.startsWith("/blog")
                      ? mobileActiveLink
                      : mobileInactiveLink
                  }
                >
                  Blog
                </Link>
                {/* <Link
                  to="/about"
                  className={
                    location.pathname.startsWith("/about")
                      ? mobileActiveLink
                      : mobileInactiveLink
                  }
                >
                  About Me
                </Link> */}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </Scrollspy>
  );
};
