import React, { FC } from "react";
import Headroom from "react-headroom";
import { WindowLocation } from "@reach/router";

import { Footer } from "./Footer";
import { Header } from "./Header";
import { Seo, ISeo } from "./SEO";

interface Props {
  // children: React.ReactChild[] | React.ReactChild;
  location: WindowLocation<unknown>;
  seo: ISeo;
  backgroundColor?: string;
}

export const Layout: FC<Props> = ({
  children,
  location,
  seo,
  backgroundColor = "gray-50",
}) => {
  return (
    <>
      <Seo {...seo} />
      <Headroom style={{ zIndex: "20" }}>
        <Header location={location} />
      </Headroom>
      <div className={`font-urbanist min-h-full bg-${backgroundColor}`}>
        {children}
      </div>
      <Footer />
    </>
  );
};
