import { graphql, useStaticQuery } from "gatsby";
import React, { FC } from "react";

export const Footer: FC = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          year
        }
      }
    }
  `);

  const { title, year } = data.site.siteMetadata;

  return (
    <footer className="bg-blue-900 font-urbanist">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-center lg:px-8">
        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-center text-base text-white">
            &copy; {year} {title}. All rights reserved.
          </p>
          {/* <p className="mt-1 text-center text-md text-gray-900">
            Site built by{" "}
            <a
              href="https://williambashelor.com"
              target="_blank"
              rel="noopener noreferrer"
              className="focus:outline-none"
            >
              <span className="underline">William Bashelor</span>{" "}
            </a>
          </p> */}
        </div>
      </div>
    </footer>
  );
};
