import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

export interface ISeo {
  description: string;
  keywords: string[];
  title: string;
  image?: string;
  url?: string;
  author?: string;
  schemaMarkup?: any;
}

export const Seo: FC<ISeo> = ({
  description,
  keywords,
  title,
  image,
  url,
  author,
  schemaMarkup = null,
}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          image
          author
          siteUrl
        }
      }
    }
  `);

  const metaDescription = description || data.site.siteMetadata.description;
  const metaTitle = title || data.site.siteMetadata.title;
  const metaImage = image || data.site.siteMetadata.image;
  const metaAuthor = author || data.site.siteMetadata.author;
  const metaKeywords = keywords || ["Nephrology", "MD", "Kidney", "Doctor"];
  const metaUrl = url || data.site.siteMetadata.siteUrl;

  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={title}
      meta={[
        { name: `description`, content: metaDescription },
        { property: `og:title`, content: metaTitle },
        { property: `og:description`, content: metaDescription },
        { property: `og:type`, content: `website` },
        { property: `locale`, content: `en_US` },
        { property: "og:image", content: metaImage },
        { property: `og:author`, content: metaAuthor },
        { property: `og:url`, content: metaUrl },
      ].concat(
        metaKeywords && metaKeywords.length
          ? {
              name: "keywords",
              content: metaKeywords.join(`, `),
            }
          : []
      )}
      link={[{ rel: "canonical", href: metaUrl }]}
    >
      {schemaMarkup ? (
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      ) : null}
    </Helmet>
  );
};
